import React, { useState, useEffect } from 'react';
import Form from './../components/form';
import Banner from './../components/banner';
import Title from './../components/title';
import Boxes from './../components/boxes';
import Editor from './../components/editor';
import Video1 from './../images/video1.mp4';
import Video2 from './../images/video2.mp4';
import Video3 from './../images/video3.mp4';
import Video4 from './../images/video4.mp4';

const Home = (props) => {
  let [ load, setLoad ] = useState(false);
  let [ video, setVideo ] = useState(false);

  let editor = `<ol>
    <li>Wypełnij poniższy formularz: wystarczy, że podasz nam tylko swoje dane.</li>
    <li>Możesz, ale nie musisz dodać informacje o: doświadczeniu zawodowym, wykształceniu, dodatkowych kursach. Jeśli masz gotowe CV możesz też je załączyć, ale nie jest to wymagane do przesłania zgłoszenia.</li>
    <li>Po otrzymaniu zgłoszenia skontaktujemy się z Tobą w celu zaproszenia na najbliższy kurs.</li>
    <li>Po zdanym egzaminie będziesz mógł podjąć pracę w spółce.</li>
  </ol>`;

  useEffect(() => {
    if(!load) {
        randVideo();
        setLoad(true);
    }
  }, [load])

  const randVideo = () => {
      let array = [Video1, Video2, Video3, Video4];
      let randomNumber = Math.floor(Math.random() * (1 - 0 + 3) + 0);
      setVideo(array[randomNumber]);
  }

  return (
    <>
      {video &&
        <Banner video={video} />
      }
      <Title text="Najbliższy kurs rusza już 5 maja!" color="red" bold id="o-kursie" goTo="wyslij-cv" />
      <Boxes />
      <Title text="Jak zostać motorniczym?" color="blue" marginTop="30" marginBottom="18" />
      <Editor text={editor} />
      <Form />
    </>
  );

}

export default Home;
